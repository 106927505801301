import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { CasesItemImage } from "../../components/Cases/CasesSlideItem/styled"

import { Container } from "components/Layout/styled"
import {
  Cases,
  CasesListItem,
  CasesTitle,
  ImageWrapper,
  ListItemSubtitle,
  ListItemTitle,
} from "./styled"

const CasesPage = ({ data }) => {
  const { title, casesList } = data.cases

  return (
    <Cases>
      <Container>
        <CasesTitle>{title}</CasesTitle>

        <ul>
          {casesList.map(({ id, img, subtitle, title, slug }) => (
            <CasesListItem key={id}>
              <Link to={`/cases/${slug}`}>
                <ImageWrapper>
                  <CasesItemImage image={getImage(img)} alt={title} />
                </ImageWrapper>

                {subtitle && <ListItemSubtitle>{subtitle}</ListItemSubtitle>}

                <ListItemTitle>{title}</ListItemTitle>
              </Link>
            </CasesListItem>
          ))}
        </ul>
      </Container>
    </Cases>
  )
}

export default CasesPage
