import React from "react"
import { graphql } from "gatsby"

import CasesPage from "../containers/CasesPage"

import { Seo } from "components"

const Cases = ({ data }) => (
  <>
    <CasesPage data={data} />
  </>
)

export const Head = ({ location }) => (
  <Seo
    title="Cases"
    description="Our development cases"
    ogUrl={location.pathname}
  />
)

export const query = graphql`
  query {
    cases: contentfulCases {
      casesList {
        img {
          gatsbyImageData
        }
        description
        id
        projectInfo {
          projectStatus
          technologies
        }
        title
        slug
      }
      title
    }
  }
`

export default Cases
