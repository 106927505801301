import styled from "styled-components"

export const Cases = styled.div`
  padding: calc(90px + 8vh) 0 10vh 0;
`

export const CasesTitle = styled.h1`
  font-size: 60px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;

  @media (max-width: 1300px) {
    margin-bottom: 35px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    margin-bottom: 25px;
  }

  @media (max-width: 425px) {
    font-size: 30px;
    margin-bottom: 20px;
  }
`

export const CasesListItem = styled.li`
  width: calc(50% - 2rem);
  padding: 14px;
  border-radius: 12px;

  box-shadow: 0px 0.9px 1px rgba(0, 0, 0, 0.017),
    0px 2.1px 2.2px rgba(0, 0, 0, 0.024), 0px 3.9px 4.1px rgba(0, 0, 0, 0.03),
    0px 6.9px 7.1px rgba(0, 0, 0, 0.036), 0px 13px 13.5px rgba(0, 0, 0, 0.043),
    0px 31px 36px rgba(0, 0, 0, 0.06);

  :nth-child(even) {
    margin-left: auto;
  }

  :not(:first-child) {
    margin-top: -216px;
  }

  img {
    // height: 430px;
    background-size: cover;
  }

  @media (max-width: 1300px) {
    width: calc(50% - 1rem);

    :not(:first-child) {
      margin-top: -17.5vw;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;

    :nth-child(odd) {
      margin-left: 0;
    }

    :not(:first-child) {
      margin-top: 0;
    }

    :not(:last-child) {
      margin-bottom: 30px;
    }

    img {
      height: auto;
    }
  }
`

export const ImageWrapper = styled.div`
  border-radius: 12px;

  img {
    transition: transform 0.2s ease-in-out 0s !important;
  }

  &:hover img {
    transform: scale(1.08);
  }
`

export const ListItemSubtitle = styled.p`
  padding-top: 20px;
  font-size: 18px;
  line-height: 1.6;

  @media (max-width: 425px) {
    font-size: 16px;
    padding-top: 15px;
  }
`

export const ListItemTitle = styled.h2`
  margin-top: 30px;
  border-radius: 12px;

  @media (max-width: 425px) {
    margin-top: 15px;
    font-size: 19px;
  }
`
